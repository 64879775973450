.selected-card{
  background: rgba(227, 247, 239, 1);
  margin-bottom: 12px;
}

.not-selected-card {
  background: #fff8f8;
  margin-bottom: 12px;
}

.radio-default {
  border: solid 1px #ccc;
  padding: 15px;
  border-radius: 50%;
  background: white;
}

.btn-close{
    border-radius: 50%;
    border: 1px solid #d8d8d8;
    color: #d8d8d8;
    box-shadow: none !important;
    font-size: 10px;
}