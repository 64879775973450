.disputeReasons {
  .selected {
    background-color: #edfdf3;
    border: solid 1px #44ba88;
  }

  .notSelected {
    background-color: #f2f5f8;
    border: solid 1px #cbd1d9;
  }

  .reasons {
    margin: 10px 10px 10px 0;
    padding: 5px 15px;
  }
  .upload-btn {
    border: 1px dashed #cbd1d9;
    text-align: center;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    span{
        color: #2968DD;
        font-weight: 500;
        display: inline-block;
    }
       
  }
}
