.text-orange{
  color: rgba(253, 129, 5, 1);
}
.light-white{
  background: rgba(255, 255, 255, 0.06);

}
.mxw-550{
  max-width: 550px;
}
.fs-50{
  font-size: 50px;
}
.text-box{
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}
.btn-lightgreen{
  background: rgba(24, 181, 87, 1);
}
.btn-blue{
  background: rgba(0, 70, 175, 1);
}
.btn-orange{
  background: rgba(247, 178, 59, 1);
}
.shadow-box{
  box-shadow: 0px 0px 16px 3px rgba(222, 235, 255, 0.64);

}
.mxw-600{
  max-width: 600px;
}
.blue-bg{
  background: linear-gradient(180deg, #C4DBFF 36.25%, #FFFFFF 100%);
}
.green-bg{
  background: linear-gradient(180deg, #A5F3C4 0%, rgba(165, 243, 196, 0) 100%);

}
.orange-bg{
  background: linear-gradient(180deg, #F9C976 0%, rgba(249, 201, 118, 0) 100%);

}
.text-blue{
  color: rgba(0, 96, 240, 1);

}
.debt-payment-wrapper {
  max-height: calc(100% - 65px);
  @media screen and (max-width:575px) {
    max-height: 100%;
  }
}
.ZI-2{
  z-index: 2;
}
.slide-right{
transform: translateX(-100%);
animation: slideRight 1s linear 1s forwards;
}
.slide-left{
  transform: translateX(100%);
  animation: slideLeft 1s linear 1s forwards;
}
@keyframes slideRight {
  0%{
    transform: translateX(-100%);
  }
  100%{
transform: translateX(0);
  }
  
}
@keyframes slideLeft {
  0%{
    transform: translateX(100%);
  }
  100%{
transform: translateX(0);
  }
  
}
.tooltip-services{
  background: black;
  color: #fff;
  left: 0;
  bottom: 120%;
  z-index: 5;
  &::after{
      height: 8px;
      width: 8px;
      content: "";
      position: absolute;
      background: rgb(0, 0, 0);
      bottom: -4px;
      left: 18px;
      transform: rotate(45deg);
  }
}
.green-bg-btn{
  background-color: #18B557 !important;
  border: #18B557 !important;
}

.grey-106 {
  color: #6A6A6A;
}
.fts-42{
  font-size: 42px;
}
.fs-22{
  font-size: 22px;
}