@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Mulish:wght@200..1000&family=Playwrite+AR:wght@100..400&family=Playwrite+AT:ital,wght@0,100..400;1,100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Noto+Serif+JP:wght@200..900&family=Playwrite+AT:ital,wght@0,100..400;1,100..400&family=Playwrite+HR:wght@100..400&family=Playwrite+HU:wght@100..400&display=swap');

.payment-wrapper {
  max-height: calc(100% - 65px);
  @media screen and (max-width:767px) {
    max-height: 100%;
  }
}

.font-icon {
  animation: pulse 2s ease-in-out infinite;
  border: 1px solid rgba(114, 189, 114, 0.8);

  &:hover {
    animation: none;
  }
}

@keyframes pulse {
  50% {
    transform: scale(.7);
    box-shadow: 0 0 0 15px rgba(114, 189, 114, 0.2);
  }
}

@media screen and (max-width: 575px) {
  .heading-text {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }

  .switch {
    height: 22px;
    width: 35px;

    .slider {

      &:before {
        height: 15px;
        width: 15px;
        left: 3px;
        bottom: 4px;
      }
    }

    input:checked+.slider:before {
      transform: translateX(14px);
    }
  }

  .fs-18 {
    font-size: 16px;
  }
}


.card-images {
  img {
    max-width: 30px;
  }
}
@media (max-width: 768px) {
  .mobile-padding{
    padding: 20px 20px 20px 20px ;
  }
}
.debt-footer{
  height: 65px;
}
.fts-42{
  font-size: 42px;
}