.uploaded-img {
    display: block;
    max-width: 50px;
    padding: 0;
    img {
        width: 50px;
        height: 50px;
    }
    .deleteCross {
        width: 20px;
        height: 20px;
        color: white;
        border: 1px solid #797F87;
        /* filter: brightness(0.5); */
        border-radius: 50%;
        padding: 4px;
        position: absolute;
    }
}
.form-wrapper {
    max-height: calc(100% - 65px);
  }
  .mxw-750{
    max-width: 750px;
  }
