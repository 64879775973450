@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200..1000&display=swap');

.overlay{
    background: #0008;
    z-index: 3;
    left: 0;
    top: 0;
}
.fm-spinner{
    background: #183813;
    height: 60px;
    width: 60px;
    animation: rotate-spinner 1s linear infinite;
    filter:drop-shadow(3px 0px 2px #fff);
    // border-top: 3px solid #fff;
    // #ace7b0
   
}

@keyframes rotate-spinner {
    0%{
transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}


@mixin scroll($bgColor, $shadow, $display) {
    &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
        box-shadow: none;
        background-color: $bgColor;
        display: $display;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: transparent;
        box-shadow: $shadow;
    }

    &:hover,
    :focus {
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.25);
        }
    }
}

* {
    font-family: "Mulish", sans-serif;
    @include scroll(transparent, none, unset);
}

.show-scroll-bar {
    @include scroll(rgba(0, 0, 0, 0.12), inset 0 0 0 12px rgba(0, 0, 0, 0.25), unset);
}

.hide-scroll-bar {
    @include scroll(transparent, none, none);
}


.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}
.fs-32 {
    font-size: 32px;
}
.fs-36 {
    font-size: 36px;
}
.fs-40{
    @media screen and (max-width: 480px){
        font-size: 24px;
    }
}
.fs-42{
    font-size: 42px;
    @media screen and (max-width: 480px){
        font-size: 24px;
    }
}
.fs-46{
    font-size: clamp(36px, 5vw, 46px);;
  }
.fs-60{
    font-size: clamp(24px, 4vw, 60px);
  }
.fs-46-sm{
    @media screen and (max-width: 768px){
        font-size: 36px;
    }
}
 @media screen and (min-width: 768px) {
     .fs-md-24 {
         font-size: 24px;
     }
 }
 .white-gradient {
background: rgb(255 255 255 / 10%);
  }
  
.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}
.hw-20{
    height: 20px;
    width: 20px;
    }
.hw-24{
    height: 24px;
    width: 24px;
}
.hw-32{
height: 32px;
width: 32px;
}
.hw-40{
height: 40px;
width: 40px;
}
.hw-50{
    height: 50px;
    width: 50px;
}
.hw-56{
    height: 56px;
    width: 56px;
    min-width: 56px;
}
.hw-65{
    height: 65px;
    width: 65px;
    @media screen and (max-width:767px) {
        height: auto;
    }
}
.hw-72{
    min-height: 72px;
    width: 72px;
    }
.hw-80{
height: 80px;
width: 80px;
}
.hw-95{
height: 95px;
width: 95px;
}

.grey-text {
    color: #7E7E7E;
}

.dark-grey {
    color: #464747;
}

.bg-lightgrey {
    background: #F8F8F8;
}
.bg-darkblue{
    background: rgb(0, 37, 94);
    @media screen and (max-width:575px) {
        background: #fff;
    }
}
.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 991;
    transition: all .3s ease-in-out;
}


.input_field {
    border: 1px solid #C9CAD9;
    border-radius: 8px;
    padding: 10px 16px;
    margin: 8px 0;

    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }
}

.input_label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    transition: 0.2s ease all;
    color: #aaa;
    pointer-events: none;
    transform-origin: left;
}

.input_container {
    position: relative;

    .input_field {
        padding: 20px 16px 10px;
    }
}

.input_container .input_field:focus~label,
.input_container .input_field:not(:placeholder-shown)~label {
    top: 0;
    left: 0;
    transform: translate(16px, 9px) scale(0.7);
}

.input_container .input_field:focus {
    border-color: #007bff;
    outline: none;
}
.link-text{
    color:#4B58CA ;
}
.cursor {
    cursor: pointer;
  }

// ---------------------Radio Buttons

/* Common Styles for Radio Buttons */
input[type="radio"] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 14px;
}

input[type="radio"] + label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 50%;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked + label:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 1;
}

/* Unchecked Radio Button */
input[type="radio"] + label:before {
    border: 2px solid #C3C8CD;
    background-color: #fff;
}

/* Primary Radio Button */
input[type="radio"].primary-radio:checked + label:before {
    background-color: #fff;
    border: 2px solid #FFE66D;
}

input[type="radio"].primary-radio:checked + label:after {
    background-color: #FFE66D;
}

/* Secondary Radio Button */
input[type="radio"].secondary-radio:checked + label:before {
    border: 2px solid #275B1F;
    background: #F7FAF7;
}

input[type="radio"].secondary-radio:checked + label:after {
    background-color: #275B1F;
}


.overlay{
    background: #0008;
    z-index: 3;
    left: 0;
    top: 0;
}

@keyframes rotate-spinner {
    0%{
transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.decoration-none{
    text-decoration: none;
    list-style: none;
}
.label-primary {
    color: #0A6B89;
}

.label-success {
    color: #00BC8B;
}

.label-danger {
    color: #FF0909;
}

.label-warning {
    color: #FF902B;
}

.dropdown-options{
    z-index: 2;
    top: 36px;
    border-radius: 10px;
    min-width: 170px;
    box-shadow: 0px 2px 8px -1px black;
    li{
        &:hover{
            background: #A5F3C4;
        }
    }
}
.modal-dialog {
    pointer-events: unset;
}

.switch {
    position: relative;
    display: inline-block;
    height: 24px;
    width: 50px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
   
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color:  #2c6823 ;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .radius-4{
    border-radius: 4px;
  }
  .radius-8{
    border-radius: 8px;
  }
  .radius-16{
    border-radius: 16px;
  }
  .toggle-arrow{
    transition:all .4s ease-in-out;
    &.rotate{
        transform: rotate(-180deg);
    }
}

.remove-border.active,.remove-border:focus, .remove-border-item:hover{
    color: #000;
    background-color: unset;
}
.btn {
    padding: 10px 14px;
    min-width: 100px;
&:hover, &:focus, &:active{
    box-shadow: none;
}
    &.btn-primary {
        background: #275B1F;
        border: 1px solid #275B1F;
        color: #fff;
    }
    &.btn-secondary {
        background: rgb(255, 193, 7);
        border: 1px solid rgb(255, 193, 7);
        color: #000;
       font-weight: 700;
    }
    &.btn-outline {
        border: 1px solid #D0D5DD;
        background: #fff;
        color: #000;
    }
    &.disable{
        opacity: 0.5;
        cursor: unset;
        background: #E4E4E4;
    }
}
.btn:disabled {
    opacity: 0.5;
    cursor: unset;
    background: #E4E4E4;
    border: none;
}
.btn-close{
    border-radius: 50%;
    border: 1px solid #d8d8d8;
    color: #d8d8d8;
    box-shadow: none !important;
    font-size: 10px;
}

.mxw-130 {
    max-width: 130px;
}
.mxw-400 {
    max-width: 400px;
}
.mxw-420 {
    max-width: 420px;
}
.mxw-460 {
    max-width: 600px;
}
.mxw-600 {
    max-width: 460px;
}
.mnh-140{
min-height: 140px;
}

.fs-30 {
    font-size: 30px;
}

.fs-10 {
    font-size: 10px;
}
.break-all{
word-break: break-all;
}
.content-wrapper{
    height: calc(100vh - 72px);
}
@media screen and (min-width:576px) {
    .overflow-sm-auto {
        overflow: auto;  
        height: 100%;      
    }

    .overflow-sm-hidden {
        overflow: hidden !important;
    }

    .w-sm-50 {
        width: 50%;
    }
}

@media screen and (min-width:768px) {
    .overflow-md-auto {
        overflow: auto !important;
    }

    .overflow-md-hidden {
        overflow: hidden !important;
    }
}

@media screen and (min-width:992px) {
    .overflow-lg-hidden {
        overflow: hidden !important;
    }

    .overflow-lg-auto {
        overflow: auto !important;
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        height: 100%;
    }
    max-height: 35px;
}

.BBBBBB {
    color: #BBBBBB !important;
}

.grey-106 {
    color: #6A6A6A;
}

.grey-85 {
    color: #414141;
}
.black-70{
    color: rgba(70, 71, 71, 1);
}
.black-65{
    color: rgba(65, 65, 65, 1);
}
.black-40{
    color: rgba(40, 40, 40, 1);
}
.black-16{
    color: rgba(16, 24, 40, 1);
}
.black-19{
    color: rgba(19, 25, 33, 1);
}
.black-24{
    color: rgba(24, 24, 24, 1);
}
.grey-106{
    color: rgba(106, 106, 106, 1);
}
.grey-102{
    color: rgba(102, 112, 133, 1);
}
.grey-70{
    color: rgba(70, 71, 71, 1);
}
.grey-126{
    color: rgba(126, 126, 126, 1);
}
.grey-106{
    color: rgba(143, 143, 143, 1);
}
.grey-143{
color: rgba(143, 143, 143, 1);
}
.text-light-yellow{
    color: rgba(250, 211, 142, 1);
    @media screen and (max-width:576px) {
        color: rgba(247, 178, 59, 1);
    }
}
@media screen and (min-width:576px) {
    .text-sm-white {
        color: #fff;
    }

    .h-sm-100 {
        height: 100%;
    }
    .w-sm-auto{
        width: auto !important;
    }
}
.footer-text{
    color:rgba(18, 44, 11, 1) ;
}